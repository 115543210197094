.callout {
  z-index: 100;
}
.callout .box {
  position: relative;
/*  height: 5px;*/
  margin: 0px;
  padding: 0px;
  right: 0px;
  margin-top: 5px;
  background: #fee;
  padding: 5px;
  border: 1px solid #fcc;
  margin-left: -10px;
  border-radius: 6px;
  box-shadow: 3px 3px 4px #cbb;
  font-size: 13px;
  overflow: visible;
}
.callout .notch {
  position: absolute;
  top: -5px;
  left: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fee;
}
.callout .border-notch {
  border-bottom-color: #fcc;
  top: -6px;
}
.callout .close {
  padding-left: 10px;
  font-size: 16px;
  line-height: 12px;
  float: right;
  color: #ccc;
  cursor: pointer;
}
.callout .close:hover {
  color: #aaa;
}

.line-warning {
  background: #fcc;
}

.callout .indicator {
  position: relative;
  width: 7px;
  height: 0px;
  top: -1px;
  left: -2px;
  border: 2px solid #f00;
  border-radius: 3px;
}

.callout .scope {
  display: inline-block;
  position: relative;
  left: 15px;
  top: -15px;
  border: 2px dotted #ec3;
  border-radius: 10px;
  padding: 3px;
  background: rgba(255, 238, 212, 0.85);
  box-shadow: 3px 3px 4px 1px #ca3;
  
}

.scope-entry th {
  font-weight: normal;
  font-style: italic;
  text-align: right;
  padding: 0 7px 0 2px;
  border-right: 1px solid #ec3;
}

.scope-entry td {
  padding-left: 7px;
}

.callout .variable {
  display: inline-block;
  position: relative;
  left: -3px;
  top: -19px;
  border: 2px dotted #ec3;
  border-radius: 5px;
  padding: 2px;
  background: rgba(255, 238, 212, 0.85);
  box-shadow: 2px 4px 4px 1px #ca3;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.callout table {
  display: inline-block;
  vertical-align: middle;
}
.callout .expression-list table {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 5px;
}
.callout th, .callout td {
  padding: 0 7px;
}
.callout th {
  font-weight: normal;
}

.callout .expression-list {
  position: relative;
  background: rgba(238, 255, 212, 0.85);
  border: 1px solid #ce3;
  border-radius: 5px;
  padding: 0 3px 3px 3px;
  left: -4px;
  text-align: center;
  box-shadow: 2px 3px 3px #ac3;
}

.callout .expression-list .expression {
  white-space: nowrap;
}

.callout .expression-list .node-code {
  height: 0px;
  overflow: hidden;
}

#root {
	height: 100%;
}

.app {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
}

.toolbar {
/*	height: 50px;*/
	margin-bottom: 10px;
}

.toolbar-entry {
	display: inline-block;
	margin: 0 20px 0 0;
	font-size: 14px;
  vertical-align: middle;
}

.toolbar-entry.save {
  text-align: center;
  width: 45px;
}

.toolbar-entry.execution-controls {
  display: inline-table;
}

.toolbar-entry.execution-controls .toolbar-row {
  display: table-row;
}

.toolbar-entry.execution-controls .toolbar-row+.toolbar-row span {
  padding-top: 3px;
}

.toolbar-entry.execution-controls .toolbar-row span.name {
  display: table-cell;
  font-weight: bold;
  text-align: right;
  padding-right: 10px;
}
.toolbar-entry.execution-controls .toolbar-row span.name:after {
  content: ":";
}


.toolbar-entry.execution-controls .toolbar-row span.control {
  display: table-cell;
  text-align: left;
  font-style: italic;
  font-weight: thin;
}

.toolbar-entry.execution-controls .toolbar-row span.control label {
  font-style: normal;
  padding-right: 20px;
}

.toolbar-entry:last-child {
	margin: 0;
}

.toolbar-entry input {
	position: relative;
	top: 4px;
}

.toolbar-entry input[type="checkbox"] {
  position: relative;
  top: revert;
}

.cm-container {
	border: 1px solid #ccc;
	flex-grow: 1;
	/* display: flex; */
	position: relative;
}

.CodeMirror {
	position: absolute;
	top: 0; bottom: 0;
}

body {
	height: 100%;
  color: #333;
}

html {
	height: 100%;
}

.editor-panel {
	position: relative;
	display: flex;
  flex-grow: 1;
	flex-direction: row;
  max-height: 100vh;
	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
}


#rudy-canvas-parent {
	display: block;
	width: 340px;
	height: 340px;
  padding: 0 10px;
}

#rudy-sidebar {
	display: flex;
	flex-direction: column;
	width: 360px;
	position: relative;
	top: 0px;
	bottom: 0px;
	/* overflow-y: scroll; */
}

.rudy-syntax-helper {
	width: 340px;
	padding: 0 10px;
	position: relative;
	top: 0px;
  flex-grow: 1;
  overflow: scroll;
	/* bottom: 0px; */
}

.button {
	border-radius: 5px;
	border: 0;
	padding: 7px 10px;
	color: white;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
}

.run.button, .resume.button, .pause.button {
	background: #0080FF;
}

.stop.button {
  background: #FB1407;
  margin-left: 5px;
}

.step.button {
  background: #008000;
  margin-left: 5px;
}

.advance.button {
  background: #008000;
}

.label {
  color: white;
  font-weight: bold;
  display: inline-block;
  border: 0;
  border-radius: 3px;
  padding: 3px 4px 2px;
  font-size: 80%;
}

.advance.label {
  background: #008000;
}

.save.label {
  text-transform: uppercase;
}

.save.label.saved {
  background: #008000;
}

.save.label.saving {
  background: #00F;
}

.save.label.error {
  background: #F00;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.rudy-syntax-helper code {
  font-family: monospace;
  background: #eee;
  border: 1px solid #ccc;
  font-style: normal;
  white-space: nowrap;
}

table p {
  margin: 0;
}

table {
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.rudy-syntax-helper td {
  padding-left: 5px;
  padding-bottom: 5px;
}

.rudy-syntax-helper td p {
  padding-left: 10px;
}
.rudy-syntax-helper td:first-child {
  text-align: right;
}


.stack-view {
  position: absolute;
  top: 5px;
  right: 365px;
  z-index: 1000;
  display: inline-block;
  width: 200px;
  text-align: right;
  border: 1px solid #ccc;
  background: white;
}

.stack-scope-view {
  border-left: 2px solid #fcc;
}

.stack-frame-view {
  border-bottom: 2px solid #ccc;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.stack-frame-view .code-view {
  display: inline-block;
  text-align: left;
}

.canvas-toolbar {
  text-align: right;
}

.button.refresh {
  background: #999;
}
.button.refresh:disabled {
  background: #ccc;
  cursor: wait;
}

/* for evaluation */
/* for i in 0...24 { print(".eval\(i) {\n  background: rgb(255, \(240-i*10), \(240-i*10));\n}") } */ 
.eval0 {
  background: rgb(255, 240, 240);
}
.eval1 {
  background: rgb(255, 230, 230);
}
.eval2 {
  background: rgb(255, 220, 220);
}
.eval3 {
  background: rgb(255, 210, 210);
}
.eval4 {
  background: rgb(255, 200, 200);
}
.eval5 {
  background: rgb(255, 190, 190);
}
.eval6 {
  background: rgb(255, 180, 180);
}
.eval7 {
  background: rgb(255, 170, 170);
}
.eval8 {
  background: rgb(255, 160, 160);
}
.eval9 {
  background: rgb(255, 150, 150);
}
.eval10 {
  background: rgb(255, 140, 140);
}
.eval11 {
  background: rgb(255, 130, 130);
}
.eval12 {
  background: rgb(255, 120, 120);
}
.eval13 {
  background: rgb(255, 110, 110);
}
.eval14 {
  background: rgb(255, 100, 100);
}
.eval15 {
  background: rgb(255, 90, 90);
}
.eval16 {
  background: rgb(255, 80, 80);
}
.eval17 {
  background: rgb(255, 70, 70);
}
.eval18 {
  background: rgb(255, 60, 60);
}
.eval19 {
  background: rgb(255, 50, 50);
}
.eval20 {
  background: rgb(255, 40, 40);
}
.eval21 {
  background: rgb(255, 30, 30);
}
.eval22 {
  background: rgb(255, 20, 20);
}
.eval23 {
  background: rgb(255, 10, 10);
}
.eval24 {
  background: rgb(255, 0, 0);
}